import { css } from "@emotion/react";
import React from "react";
import { Link } from "../../../../components/link";
import { Ul } from "../../../../components/typography";
import { publicRuntimeConfig } from "../../../../utils/next-helpers/runtime-config";
import { REGULAR_CONTENT_WIDTH } from "../../../../utils/styles";
import { ErrorPageLayout } from "../../../layouts/page-layout";

const styles = {
  container: css`
    width: ${REGULAR_CONTENT_WIDTH};
    padding: 20px;
  `,
  title: css`
    font-size: 20px;
  `,
  text: css`
    margin-top: 8px;
    font-size: 14px;
  `,
  ulContainer: css`
    display: flex;
    justify-content: center;
  `,
  ul: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
  `,
  topLinkContainer: css`
    margin-top: 36px;
    font-size: 14px;
  `,
} as const;

export const NotFoundPage: React.FC = () => {
  return (
    // ErrorBoundary のフォールバックとして使用するため ErrorBoundary で囲わない
    <ErrorPageLayout>
      <div css={styles.container}>
        <h1 css={styles.title} data-testid="internalErrorPage">
          ページが見つかりませんでした。（404）
        </h1>
        <p css={styles.text}>
          アクセスしようとしたページは削除もしくは移動した可能性があります。
          <br />
          問題が繰り返し発生する場合や時間をおいても改善されない場合は、お問い合わせください。
        </p>
        <div css={styles.ulContainer}>
          <Ul size="caption" css={styles.ul}>
            <li>
              <Link
                href={publicRuntimeConfig.getString("NEXT_PUBLIC_FEEDBACK_FORM_URL") ?? undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                問い合わせ方法を確認する
              </Link>
            </li>
            <li>
              <Link href="mailto:accounting.feedback@moneyforward.com">メールで問い合わせる</Link>
            </li>
          </Ul>
        </div>
        <div css={styles.topLinkContainer}>
          <Link href="/">トップへ</Link>
        </div>
      </div>
    </ErrorPageLayout>
  );
};
